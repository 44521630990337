<template>
  <Toast/>
  <Breadcrumb :model="items"/>
  <DataTable :value="data" class="p-mt-5" :auto-layout="true">
    <template #header>
      <div class="p-d-flex" style="text-align: left">
        <Button class="p-mr-2" @click="addOrUpdateInfo(null)" label="新增轮播图" icon="pi pi-plus"/>
      </div>
    </template>
    <Column field="picIndex" header="序号"></Column>
    <Column field="title" header="文章名称"></Column>
    <Column field="pic" header="图片">
      <template #body="a">
        <img style="width:150px;" class="product-image" :src="url+a.data.pic"/>
      </template>
    </Column>
    <Column header="操作">
      <template #body="a">
        <div style="display: flex">
          <Button :class="{'p-disabled':a.data.picIndex==0}" @click="changeOrder(a.data.id,1)" icon="pi pi-arrow-up" class="p-button-rounded  p-mr-2 p-button-sm" />
          <Button :class="{'p-disabled':a.data.picIndex==data.length-1}" @click="changeOrder(a.data.id,2)" icon="pi pi-arrow-down" class="p-button-rounded p-mr-2 p-button-sm" />

          <Button @click="addOrUpdateInfo(a.data)"  class="p-mr-2 p-button-sm">编辑</Button>
          <Button @click="del(a.data)"  class="p-button-danger p-button-sm" label="删除"/>
        </div>
      </template>
    </Column>
  </DataTable>
<add-or-update-banner ref="addOrUpdate" v-on:close="closeDialog" v-if="show"></add-or-update-banner>
</template>

<script>

import AddOrUpdateBanner from "@/views/platform/publicityContentManagement/AddOrUpdateBanner";
export default {
  name: "",
  components: {AddOrUpdateBanner},
  data() {
    return {
      type:null,
      url:this.$global_msg.imgurl,
      items: [
        {label: '宣传内容管理', to: '/bannerGraph'},
        {label: '轮播图管理', to: '/bannerGraph'}
      ],
      show: false,
      List: [],
      data:[],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let ths = this;
      this.$http.get('/Rotating/getAll', {params: {type: 1}}).then((res) => {
        console.log(res)
        ths.data = res.data
      })
    },
    changeOrder(id,order){
      this.$http.post('/Rotating/changeOrder/'+id+'/'+order).then(()=>{this.init()})
    },
    del(data){
      this.$http.post('/Rotating/del?id='+data.id).then(()=>{})
      this.$toast.add({severity: 'success', summary: '删除成功', life: 3000});
      this.init()
    },
    addOrUpdateInfo(data) {
      this.show = true
      this.$nextTick(()=>{
        this.$refs.addOrUpdate.init(data)
      })

    },
    closeDialog(){
      this.show=false
      this.init()
    }
  }
}
</script>

<style scoped>

</style>