<template>
  <Dialog style="width:50%" v-model:visible="show" @hide="closeDialog" header="轮播图">
    <div>

      <div class="p-flex-column p-mt-4">
        <label>所属：</label>
        <Dropdown style="width:100%;" @change="getColumn" v-model="type"
                  :options="belongList" optionLabel="belong" optionValue="value"
                  placeholder="选择类别"/>
      </div>
      <div class="p-flex-column p-mt-4">
        <label>类别：</label>
        <Dropdown style="width:100%;" v-model="columnId" :options="columnIdList" @change="getArticleList"
                  optionLabel="title" optionValue="id" placeholder="选择类别"/>
      </div>

      <div class="p-fluid p-mt-4">
        <label>关联文章：</label>
        <Dropdown v-model="form.articleId" @change="addArticle"
                  :options="articleList" optionLabel="title" optionValue="id" placeholder="选择任务"/>
      </div>
      <div class="p-fluid p-mt-4">
        <h6>图片尺寸比例：12:5，建议大小：1920*800</h6>
        <FileUpload v-model="form.pic" name="file" :url="fileUploadUrl" chooseLabel="选择图片"
                    @upload="onUpload" @select="selectPic" auto="true" mode="basic" accept="image/*"/>
        <img class="p-mt-2" style="width: 100px" :src="picUrl">
      </div>
<!--      <div v-if="form.id" class="p-fluid p-mt-4">-->
<!--        <label>序号：</label>-->
<!--        <InputNumber style="width: 50%" :useGrouping="false" v-model="form.picIndex"></InputNumber>-->
<!--      </div>-->
      <div class="p-field p-grid p-jc-evenly p-mt-4">
        <Button @click="submit" icon="pi pi-fw pi-save" label="保存"></Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
export default {
  name: "AddOrUpdateBanner",
  data() {
    return {
      show:false,
      form: {
        id: null,
        articleId: null,
        pic: null,
        type:1,
      },
      belongList: [
        {belong: '网站', value: 1},
        {belong: '公众号', value: 2}
      ],
      fileUploadUrl: this.$global_msg.frontendUrl + "/file/uploadOne",
      picUrl: null,
      url: this.$global_msg.imgurl,
      articleList:[],
      type: null,
      columnId:null,
      columnIdList:[]
    }
  },
  methods:{
    init(data){
      this.show=true
      if(data!=null){
        this.form=data
        this.$http.get('/article/getOneArticle',{
          params:{
            id:data.articleId
          }
        }).then((res)=>{
          this.type=res.data.belong
          this.columnId = res.data.columnId.split(',')[0]
          this.picUrl=this.url+data.pic

          this.getColumn()
          this.getArticleList()
        })

      }
    },
    closeDialog(){
      this.$emit('close')
    },
    getColumn() {
      this.$http.get("/article/getColumnList", {
        params: {
          belongId: this.type
        }
      }).then((res) => {
        this.columnIdList = res.data
      })
    },
    getArticleList() {
      let ths= this
      this.$http.get('/article/getColounmArticleList', {params: {colId: this.columnId}})
          .then((res) => {
            ths.articleList = res.data
          })
    },
    selectPic(event) {
      this.picUrl = event.files[0].objectURL
    },
    onUpload(event) {
      console.log(event)
      this.form.pic = event.xhr.response
      this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000});
    },
    addArticle(a){
      this.form.articleId=a.value
    },
    submit() {
      this.$http.post('/Rotating/addOrUpdatePic', this.form).then(() => {
        this.$toast.add({severity: 'info', summary: '成功', detail: '提交成功', life: 3000});
        this.show=false
        this.closeDialog()
      })
    },
  }

}
</script>

<style scoped>

</style>